<template>
  <div class="choose-room-dialog">
    <v-dialog
      :value="visible"
      max-width="500"
      persistent
      @click:outside="$router.back()"
    >
      <v-card>
        <v-card-title
          >Select Classroom <v-spacer></v-spacer>
          <v-btn icon @click="$router.back()"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-card-text>
          <v-row justify="center" v-if="enableInactiveAcademicRooms">
            <v-col cols="12">
              <v-select
                v-model="selectedAcademicYear"
                :items="currentInstitution.academic_sessions.filter(as => !as.active)"
                label="Academic Year"
                item-text="title"
                item-value="id"
                @change="fetchRooms()"
                hide-details="auto"
              ></v-select>
            </v-col>
            <v-col v-if="oldClassrooms? oldClassrooms.length : false">
              <v-select
                v-model="selectedOldClassroom"
                return-object
                :items="oldClassrooms"
                label="Classroom"
                item-text="sections"
                item-value="id"
                hide-details="auto"
              ></v-select>
            </v-col>
          </v-row>
          <v-row justify="center" v-if="!enableInactiveAcademicRooms">
            <v-col
              class="py-0"
              v-for="sectionCategory in currentInstitution.section_categories"
              :key="sectionCategory.id"
              :cols="12 / currentInstitution.section_categories.length"
            >
              <v-select
                v-model="selectedSections[sectionCategory.number]"
                :items="getValidSections(sectionCategory)"
                :label="categoryTypes[sectionCategory.type]"
                item-text="title"
                item-value="id"
                hide-details="auto"
              ></v-select>
            </v-col>
          </v-row>
          <v-row justify="center">
            <v-col class="mt-2" cols="12">
              <v-btn
                @click="enableInactiveAcademicRooms = !enableInactiveAcademicRooms"
                color="primary"
                class="mb-0"
                href
                text
                plain
                block
                small
                >{{ enableInactiveAcademicRooms ? 'Select from active year classrooms' : 'Select from previous year classrooms'}}</v-btn
              >
            </v-col>
            <v-col>
              <v-btn
                @click="selectClassroom"
                color="primary"
                block
                :disabled="!shouldShowSelectButton"
                >Select</v-btn
              >
            </v-col>
          </v-row>
        </v-card-text>
        
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import Mixins from "@utils/mixins";

export default {
  name: "ChooseRoomDialog",
  mixins: [Mixins.essentials],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    enableAllSection: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      "lastSelectedRoom",
      "accessToken",
      "currentUser",
      "currentInstitution",
      "allRooms",
    ]),
    essentials() {
      return {
        accessToken: this.accessToken,
        handleErrorsFunction: this.handleApiError,
        setLoading: this.setLoading,
      };
    },
    shouldShowSelectButton() {
      if(this.enableInactiveAcademicRooms && this.selectedOldClassroom) return true
      let totalSections = this.currentInstitution.section_categories.length;
      let shouldShow = false;
      Object.keys(this.selectedSections).map((s) => {
        if (s > totalSections) return;
        shouldShow = this.selectedSections[s] != null;
      });
      return shouldShow;
    },
  },
  data() {
    return {
      selectedAcademicYear: null,
      enableInactiveAcademicRooms: false,
      oldClassrooms: [],
      selectedOldClassroom: null,
      selectedSections: {
        1: null,
        2: null,
        3: null,
      },
      categoryTypes: {
        1: "Standard",
        2: "Division",
        3: "Batch",
        4: "Year",
        5: "Department",
      },
    };
  },
  methods: {
    ...mapActions(["showSnackbar", "setLoading", "setLastSelectedRoom"]),
    getValidSections(sectionCategory) {
      var sectionsList = sectionCategory.sections;
      if (!this.enableAllSection)
        sectionsList = sectionsList.filter((s) => s.title != "All");
      return sectionsList;
    },
    async selectClassroom() {
      this.setLoading(true);
      if(this.enableInactiveAcademicRooms && this.selectedOldClassroom) {
        this.emitRoom(this.selectedOldClassroom)
        this.setLoading(false);
        return
      }
      var room = this.allRooms.find((r) => {
        var firstMatched,
          secondMatched,
          thirdMatched = false;
        firstMatched = r.first_section
          ? r.first_section.id == this.selectedSections[1]
          : true; // if section exists, then it should match or else should return matched by default
        secondMatched = r.second_section
          ? r.second_section.id == this.selectedSections[2]
          : true;
        thirdMatched = r.third_section
          ? r.third_section.id == this.selectedSections[3]
          : true;
        return firstMatched && secondMatched && thirdMatched;
      });
      this.setLoading(false);
      if (room) {
        this.emitRoom(room);
        this.setLastSelectedRoom(room); // save in store for quicker access later
        return;
      }
      this.showSnackbar({
        title: "Classroom not found",
        text: "Please, try again!",
        type: "error",
      });
    },

    async fetchRooms(){
      this.selectedOldClassroom = null;
      var url = this.Helper.addUrlParams(this.endpoints.classroomViewSet, `session=${this.selectedAcademicYear}`) 
      this.oldClassrooms = await this.api.call(this.essentials, url)
    },

    async handleApiError(err) {
      this.moreLoading = false;
      console.log(err);
      this.setLoading(false);
      if (err.response) {
        this.showSnackbar({
          title: "Something went wrong",
          text: err.response.data.message,
          type: "error",
        });
      } else {
        this.snackbar.text = "Unable to process request";
        this.snackbar.visible = true;
      }
    },
    async emitRoom(room) {
      this.$emit("roomSelected", room);
      window.scroll(0, 0);
    },
  },
  created() {
    if (this.currentUser.is_student) this.emitRoom(this.currentUser.room);
    if (this.lastSelectedRoom) {
      this.selectedSections[1] = this.lastSelectedRoom.first_section
        ? this.lastSelectedRoom.first_section.id
        : null;
      this.selectedSections[2] = this.lastSelectedRoom.second_section
        ? this.lastSelectedRoom.second_section.id
        : null;
      this.selectedSections[3] = this.lastSelectedRoom.third_section
        ? this.lastSelectedRoom.third_section.id
        : null;
    }
  },
};
</script>